import React,{useState} from 'react'
import "./OffplanColumnBlocks.scss"
import { Container } from "react-bootstrap"
import Map from "../PropertyMap/Map"
import noImage from  "../../images/no-image.png"
import PlayVideo from "../PlayVideo/PlayVideo"
import ScrollAnimation from "react-animate-on-scroll"
import GGFXImage from "../../modules/GGFXImage"

const {
    ContentModule,
  } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")


const OffplanColumnBlocks = ({data,offplanData,isPreviewEnabled,isSecondaryBlocks}) => {
  const [isPlay, setPlay] = useState(false)

    const imagename ="new-developments.left_right_block.image_blocks"
  return (
    <div className={`offplan-blocks-wrapper ${isSecondaryBlocks?"secondary-blk":""}`}>
    <Container>
      <div className="left_right_blocks">
        {data?.left_right_block?.map((item, index) => {
            const locationInfo={
                latitude:parseFloat(item?.latitude),
                longitude:parseFloat(item?.longitude)
              }

          return (
            <ScrollAnimation className={`block_items section_items ${item?.layout}`} key={item?.title} id={item?.id_name}
             animateIn="animate__slideInUp" animateOnce delay={index*100} offset={100}>

            {/* <section className={`block_items section_items ${item?.layout}`} key={item?.title} id={item?.id_name}> */}
              <div className="image-sections">
             {item?.latitude&&item?.longitude?
                <div className="offplan_map_block">
                    <Map property_details_map_data={locationInfo} />
                </div>
                : 
            item?.image?.url||item?.image?.data?.attributes?.url?
               
                <GGFXImage
                  ImageSrc={item?.image?.data?.attributes||item?.image}
                  altText={
                    item?.image?.alternativeText
                      ? item?.image?.alternativeText
                      : item.title
                    }
                  imagetransforms={offplanData?.ggfx_results}
                  renderer="srcSet"
                  imagename={imagename}
                  strapiID={offplanData?.strapi_id}
                />
                :
                <img classNames="img-fluid" src={noImage} alt="No Image" />
                } 

                <div className="banner-contents">
                    {item.video_url && (
                      <button
                        onClick={() => setPlay(true)}
                        className="play-button"
                      >
                        <i className="icon video-filled-icon" />
                      </button>
                    )}
                  </div>
                  <PlayVideo
                    isOpen={isPlay}
                    stopPlay={setPlay}
                    videoId=""
                    isCloseFunction={setPlay}
                    videourl={item?.video_url}
                    htmlink={""}
                  />
              </div>
              <div className="content-sections">
                <h2 className="heading">{item?.title}</h2>
                {(item?.description||(isPreviewEnabled&&item?.description))&&
                <p className="description">
                  <ContentModule
                    Content={item?.description}
                  />
                </p>
                }
              </div>
            </ScrollAnimation>
          )
        })}
      </div>
    </Container>
    
  </div>
  )
}

export default OffplanColumnBlocks