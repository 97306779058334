import { Link } from "gatsby"
import React,{useEffect,useState} from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import noImage from "../../images/no-image.png"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./OffplanCardSlider.scss"
import _ from "lodash"
import qs from "qs"
import GGFXImage from "../../modules/GGFXImage"
import { removeDataAttributes } from "../Common/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"


const OffplanCardSlider = ({ offplanData }) => {
  const [propertiesData, setProperties] = React.useState([])
  const [loading, setLoading]=useState(false)
  const imagename = "new-developments.images.tileimg"

  const {isMobile} = useDeviceMedia()


  const increasePercent=(price,percentage)=>{
    return price+(percentage/100)*price
  }

  const decreasePercent=(price,percentage)=>{
    return price-(percentage/100)*price
  }
  const minPrice=(offplanData?.price!==0&&offplanData?.price)?decreasePercent(offplanData?.price,10):"";
  const maxPrice=(offplanData?.price!==0&&offplanData?.price)?increasePercent(offplanData?.price,10):"";

  React.useEffect(() => {
    if (!_.isEmpty(propertiesData)) return
    const query = qs.stringify(
      {
        filters: {
          $or: [
            {
              area: {
                $eq: offplanData?.area,
              },
            },
            {
              developer: {
                $eq	: offplanData?.developer,
              },
            },
            {
             price:{
              $between:(offplanData?.price!==0&&offplanData?.price)? [minPrice, maxPrice]:[],
            },
           },
          ],
          slug:{
            $ne:offplanData?.slug
          },
          search_type: {
            $eq: 
              "sales"
          },
          department: {
            $eq: "Offplan",
          },
          publish: {
            $eq: true,
          },
        },
       
        pagination: {
          pageSize: 12,
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )

    const url = `${process.env.GATSBY_STRAPI_SRC}/api/new-development?${query}&populate[ggfx_results]=*`

    setLoading(true)
    fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
      }),
    })
      .then(response => response.json())
      .then(({ data }) => {
        setProperties(data)
        setLoading(false)
      })
      .catch(err => {
        console.error("🚀CustomNoHits.js useEffect", err)
      })
  }, [])
  // if (loading) return <div>Loading...</div>

  if (_.isEmpty(propertiesData)) return null

  let  propertiesList = []
  propertiesData?.filter((data, index) => {
    if(data.attributes?.area===offplanData?.area){
      propertiesList.push(data)
    }
  })
  propertiesData?.filter((data, index) => {
    if(data.attributes?.area===offplanData?.developer){
      propertiesList.push(data)
    }
  })

  const priceProperties=propertiesData?.filter((data,index)=>{
    if(data.attributes?.area!==offplanData?.area||data.attributes?.area!==offplanData?.developer){
      return data
    }
  })

  const propertyItems=[...propertiesList,...priceProperties]
  const uniqueLabels = {}

  const offplanSimilarProp=propertyItems.filter(item=>{
    if (!uniqueLabels[item?.id]) {
      uniqueLabels[item?.id] = true;
      return true;
    }
  return false;
  })



  return (
    <div className="offplan-newdev-slider">
      <Container>
        <h2 className="title">Other properties that may interest you</h2>
        <CustomSlider className="offplan-prop-slider" slidecount={isMobile?1:4}>
          {offplanSimilarProp?.slice(0,4)?.map((node, index) => {
            const image =
              node?.attributes?.images?.length > 0
                ? node?.attributes?.images[0]
                : ""

                const newDev=removeDataAttributes(node)
            return (
              <ScrollAnimation
                className="offplan-slider-item"
                animateIn="animate__slideInUp"
                animateOnce
                delay={index * 100}
                offset={100}
              >
                <div className="image-section">
                  <Link
                    to={`/off-plan/properties/for-sale/in-dubai/${node?.attributes?.slug}/${node?.attributes?.crm_id}`}
                  >
                    {image?.url ? (
                      <GGFXImage
                      ImageSrc={image}
                      altText={`${node?.attributes?.banner_title}`}
                      imagetransforms={newDev?.ggfx_results}
                      renderer="srcSet"
                      imagename={imagename}
                      strapiID={node?.id}
                      classNames="property-card_image"
                    />
                    ) : (
                      <img src={noImage} alt="No Image" />
                    )}
                  </Link>
                  <div className="content-section">
                    <h4>{node?.attributes?.banner_title}</h4>
                    {node?.attributes?.developer && <p>by {node?.attributes?.developer}</p>}
                  </div>
                </div>
              </ScrollAnimation>
            )
          })}
        </CustomSlider>
      </Container>
    </div>
  )
}

export default OffplanCardSlider
