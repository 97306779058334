import React from "react"
import { Container } from "react-bootstrap"
import "./PaymentBlock.scss"
import ScrollAnimation from "react-animate-on-scroll"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const PaymentBlock = ({ paymentData ,isPreviewEnabled}) => {
  return (
    <div className="payment-block-wrapper">
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <div className="payment-content">
          <h2 className="heading">{paymentData?.title}</h2>
          {(paymentData?.content ||(isPreviewEnabled&&paymentData?.content))&&
          <p className="description">
            <ContentModule Content={paymentData?.content} />
          </p>
          }
          <div className="payment-list">
            {paymentData?.payment_items?.map(item => {
              return (
                <div className="payment-item">
                  <h2 className="payment_value">{item?.payment_value}</h2>
                  <p className="payment_text">{item?.payment_text}</p>
                </div>
              )
            })}
          </div>
        </div>
      </Container>
      </ScrollAnimation>
    </div>
  )
}

export default PaymentBlock
