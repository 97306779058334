import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/offplan_brochure.json"
import DefaultForm from "../forms/default-form-layout"
import "./OffplanDetailsBanner.scss"
import GGFXImage from "../../modules/GGFXImage"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")


const OffplanDetailsBanner = ({ offplanData }) => {
  const [show, setShow] = useState(false)
  const imagename = "new-developments.images.details"

    //for preview feature data
    const getBrochure= offplanData?.property_brochure?.length>0 ?
    offplanData?.property_brochure[0]?.attributes?.url:""
  
    const findImage=offplanData?.images?.strapi_json_value?.length>0?offplanData?.images?.strapi_json_value[0]
    :offplanData?.images?.length>0 ? offplanData.images[0]:""
    //for preview feature data

  const imageupload=offplanData?.upload_image
  const image = imageupload?.url ? imageupload : findImage


  const brochureUrl =
    offplanData?.property_brochure?.length > 0 &&
    offplanData?.property_brochure[0]?.url? offplanData?.property_brochure[0]?.url:
    getBrochure?getBrochure:""

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const moveToForm = () => {
    const getId = document.getElementById("offplan-contact-form")
    getId.scrollIntoView({ behavior: "smooth" })
  }

  const formTitle = "Download the Brochure"
  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <div className="offplan-details-banner">
      {image?.url&&
        <GGFXImage
            ImageSrc={image}
            altText={`${
              offplanData?.alternativeText
                ? offplanData?.alternativeText
                : offplanData.banner_title
            } banner`}
            imagetransforms={offplanData?.ggfx_results}
            renderer="bgImg"
            imagename={imagename}
            strapiID={offplanData?.strapi_id}
            className="banner-img"
        />
    }
      <div className="overlay-bg"></div>
      <div
        // animateIn="animate__slideInUp"
        // animateOnce
        // delay={100}
        // offset={100}
      >
        <Container>
          <div className="banner-content-section">
            <div className="banner-content">
              <div className="offplan-logo">
              {offplanData?.property_developer_logo?.url&&
                <img src={offplanData?.property_developer_logo?.url} />
              }
              </div>
              <h1>{offplanData?.banner_title}</h1>
              <p className="developer">
                by <span>{offplanData?.developer}</span>
              </p>
              <p className="description">
                <ContentModule
                  Content={offplanData?.banner_content}
                />
              </p>
              
              <div className="cta-section">
                <a onClick={moveToForm} className="button button-filled-green">
                  Register Interest
                </a>

                {brochureUrl && (
                  <a
                    className="button button-outline-white"
                    href="javascript:void(0)"
                    onClick={handleModal}
                  >
                    Download Brochure
                  </a>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form news_subscribe offplan-brochure"
      >
        <Modal.Body>
          <div className="popup-form-wrapper news_subscribe">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                sourceUrl={pageurl}
                phone_id_value={"brochure_download-button"}
                fields={FormFields}
                prop_address={offplanData?.banner_title}
                formTitle={formTitle}
                brochureUrl={brochureUrl}
                to_email_id={
                  offplanData?.additional_email
                    ? offplanData?.additional_email +
                      ", hauswebsiteleads@gmail.com"
                    : "hauswebsiteleads@gmail.com"
                }
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default OffplanDetailsBanner
